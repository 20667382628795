.app_logo {
  position: absolute;
  top: 1rem;
  right: 2rem;
}

.app_logo img {
  max-width: 50px;
}

.container {
  width: 100%;
  padding: 1rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  direction: rtl;
  background-image: url(../../assets/images/wave.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100vh;
  position: relative;
}

.login_card_media {
  flex: 1 0 0;
}

.login_card_media img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.login_card {
  display: flex;
  flex-direction: row;
  background-color: var(--clr-white);
}

.card_form {
  flex: 1 0 0;
  padding: 3rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.card_form h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 1.688rem;
  line-height: 1.313rem;
  color: var(--clr-dark-brown);
}

.login_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  margin-top: 2rem;
}

.login_form h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 1.438rem;
  line-height: 1.313rem;
  padding-bottom: 2rem;
}

.input_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
}

.input_wrapper label {
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.313rem;
  color: var(--clr-black);
}

.input_wrapper label::after {
  content: '*';
  color: red;
  margin-right: 0.5rem;
}

.input_wrapper input {
  width: 100%;
  border: unset;
  border-radius: 2rem;
  padding: 0.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.313rem;
  color: var(--clr-black);
  background-color: #f7f7f7;
  padding: 1rem 2rem;
}

.input_wrapper input::placeholder {
  color: var(--clr-gray);
}

.input_wrapper[data='input_remember'] {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
}

.input_wrapper[data='input_remember'] label::after {
  content: '';
}

.input_wrapper input[type='checkbox'] {
  width: fit-content;
  border: 1px solid var(--clr-black);
  font-size: 1rem;
}

.input_wrapper button {
  background-color: var(--clr-primary);
  border-radius: 2.12rem;
  padding: 1.375rem 5.438rem;
  font-family: var(--ff-cairo);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  align-self: center;
  margin-top: 3rem;
  color: var(--clr-white);
}

.input_error {
  color: var(--clr-red);
  font-size: 1rem;
  line-height: 1.313rem;
  margin-top: 1rem;
}
