@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap');

/*-----------------*/
/* Custom properties */
/*----------------*/

:root {
  /* Colors */
  --clr-primary: #4b4cb6;
  --clr-light-blue: #5574f8;
  --clr-dark-blue: #4b4bb5;
  --clr-dark-brown: #472404;
  --clr-white: #fff;
  --clr-black: #000;
  --clr-gray: #9b9b9b;
  --clr-light-gray: #f7f7f7;
  --clr-dark-gray: #343434;
  --clr-red: #b51919;
  --clr-turquoise: #a8cfd8;
  --clr-green: #37b34a;

  /* font-families */
  --ff-cairo: 'Cairo', sans-serif;
}

/*-----------------*/
/* RESET */
/*----------------*/

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}
/* Remove default link style */
a {
  color: unset;
  text-decoration: none;
  cursor: pointer;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--ff-cairo);
  font-size: var(--fs-400);
  background-color: var(--clr-white);
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

textarea {
  all: unset;
}

/* Remove default button styles */
button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/*-----------------*/
/* Utility classes  */
/*----------------*/

.flex {
  display: flex;
  gap: var(--gap, 1rem);
}

.grid {
  display: grid;
  gap: var(--gap, 1rem);
}

.aligen-items-center {
  align-items: center;
}

.justify-content {
  justify-content: center;
}

.container {
  padding-inline: 2em;
  max-width: 80rem;
  margin-inline: auto;
}

.uppercase {
  text-transform: uppercase;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

span {
  margin: 0 !important;
}

.map-container {
  width: 100%;
  height: 400px;
}
